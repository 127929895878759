import { endpoints, ID } from '@rossum/api-client';
import { SchemaRule } from '@rossum/api-client/schemas';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';
import { RULES_QUERY_KEY } from './rulesQueryKeys';

export const usePatchRule = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([RULES_QUERY_KEY]);
    },
    mutationFn: ({
      patchedRuleId,
      patchedRule,
    }: {
      patchedRuleId: ID;
      patchedRule: Partial<SchemaRule>;
    }) => api.request(endpoints.rules.patch(patchedRuleId, patchedRule)),
  });
};
