import { IconCirclePlus } from '@rossum/ui/icons/tabler';
import {
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { SettingsBreadcrumbs } from '../../../containers/Settings/SettingsBreadcrumbs';
import { Header } from '../../../ui/header/Header';
import { ItemTile } from '../../../ui/item-tile/ItemTile';
import { ItemTileDescription } from '../../../ui/item-tile/ItemTileDescription';
import { RuleActionIcons } from '../../rules/components/RuleActionIcons';
import { useListRuleTemplates } from '../hooks/useListRuleTemplates';

const RuleTemplatesPageEmpty = ({
  createRuleTemplateUrl,
}: {
  createRuleTemplateUrl: string;
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  return (
    <Box>
      <Stack
        spacing={2}
        textAlign="center"
        alignItems="center"
        sx={{
          transform: 'translateY(80%)',
        }}
      >
        <IconCirclePlus
          onClick={() => history.push(createRuleTemplateUrl)}
          stroke={1}
          size={64}
          color={theme.palette.secondary.main}
          cursor="pointer"
        />
        <Stack spacing={1}>
          <Typography variant="h5" color="text.secondary">
            {intl.formatMessage({
              id: 'features.ruleTemplates.list.empty.title',
            })}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage(
              {
                id: 'features.ruleTemplates.list.empty.description',
              },
              {
                link: (chunks: ReactNode[]) => (
                  <Link
                    to={createRuleTemplateUrl}
                    style={{
                      textDecoration: 'underline',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {chunks}
                  </Link>
                ),
              }
            )}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export const RuleTemplatesPage = () => {
  const intl = useIntl();
  const { data: ruleTemplates, isLoading } = useListRuleTemplates();

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          scrollableDivRef={params.scrollableDivRef}
          breadcrumbs={
            <SettingsBreadcrumbs
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: 'containers.settings.templates.header.title',
                  }),
                },
              ]}
            />
          }
          buttons={[
            <Button
              key="create-rule-template"
              variant="contained"
              component={Link}
              to="/settings/rule-templates/new#copilot"
            >
              {intl.formatMessage({
                id: 'features.ruleTemplates.list.addRule',
              })}
            </Button>,
          ]}
          title={intl.formatMessage({
            id: 'features.ruleTemplates.list.title',
          })}
          description={intl.formatMessage({
            id: 'features.ruleTemplates.list.description',
          })}
        />
      )}
    >
      <Stack p={4} spacing={0.5}>
        {isLoading ? (
          Array.from([1, 2, 3, 4, 5]).map(item => (
            <Skeleton
              key={item}
              variant="rectangular"
              height={56}
              width="100%"
            />
          ))
        ) : ruleTemplates?.results?.length ? (
          ruleTemplates.results.map(ruleTemplate => (
            <ItemTile
              key={ruleTemplate.id}
              to={`/settings/rule-templates/${ruleTemplate.id}/detail`}
            >
              <ItemTileDescription
                isFaded={false}
                description={ruleTemplate.description}
                label={ruleTemplate.name}
                to={`/settings/rule-templates/${ruleTemplate.id}/detail`}
              />
              <RuleActionIcons ruleActions={ruleTemplate.ruleActions} />
            </ItemTile>
          ))
        ) : (
          <RuleTemplatesPageEmpty createRuleTemplateUrl="/settings/rule-templates/new#copilot" />
        )}
      </Stack>
    </PageLayoutV2>
  );
};
