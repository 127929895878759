import {
  alpha,
  Autocomplete,
  MenuItem,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { Fragment, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { docsLinks } from '../../../../../constants/values';
import { link } from '../../../../../lib/formaterValues';
import { ContentGroup } from '../../../../../ui/content-group/ContentGroup';
import { FieldsFormValues } from '../../form-model/formModels';
import { isPreFillFromAvailable } from './conditions';

type OptionType = {
  parent: string;
  value: string;
  hasVariable?: boolean;
};

const options: OptionType[] = [
  { value: 'upload', parent: 'upload:<id>', hasVariable: true },
  { value: 'email', parent: 'email:<id>', hasVariable: true },
  { parent: 'email_header', value: 'from' },
  { parent: 'email_header', value: 'to' },
  { parent: 'email_header', value: 'reply-to' },
  { parent: 'email_header', value: 'subject' },
  { parent: 'email_header', value: 'message-id' },
  { parent: 'email_header', value: 'date' },
  { parent: 'email_body', value: 'text_html' },
  { parent: 'email_body', value: 'text_plain' },
];

const isValueOptionType = (value: string | OptionType): value is OptionType =>
  typeof value !== 'string' && 'value' in value;

type PreFillFormSectionProps = {
  control: Control<FieldsFormValues> | undefined;
  formValues: FieldsFormValues;
};

const PreFillFormSection = ({
  control,
  formValues,
}: PreFillFormSectionProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const intl = useIntl();

  const handleOnChange = (
    values: (string | OptionType)[],
    setValues: (values: string[]) => void
  ) => {
    const lastValue = values[values.length - 1];

    if (lastValue && isValueOptionType(lastValue)) {
      if (lastValue.hasVariable) {
        setInputValue(`${lastValue.value}:`);
        return;
      }
    }

    const stringValues = values.map(value => {
      if (isValueOptionType(value)) {
        return `${value.parent}:${value.value}`;
      }

      return value;
    });

    setValues(stringValues);
  };

  return isPreFillFromAvailable(formValues) ? (
    <ContentGroup
      title={intl.formatMessage({
        id: 'features.queueSettings.fields.form.preFillFrom.title',
      })}
      description={intl.formatMessage(
        {
          id: 'features.queueSettings.fields.form.preFillFrom.description',
        },
        {
          link: link(docsLinks.rirFieldName),
        }
      )}
    >
      <Controller
        control={control}
        name="field.aiEngineFields"
        render={({ field: { value, onChange } }) => (
          <Autocomplete
            freeSolo
            multiple
            options={options}
            groupBy={option => option.parent}
            inputValue={inputValue}
            value={value}
            onChange={(_, values) => handleOnChange(values, onChange)}
            onInputChange={(_, value) => setInputValue(value)}
            getOptionLabel={option =>
              typeof option === 'string' ? option : option.value
            }
            slotProps={{
              paper: {
                elevation: 8,
              },
            }}
            renderInput={params => (
              <TextField {...params} label="Select Option" variant="outlined" />
            )}
            // TODO: AutocompleteGroup.tsx from engine feature after merging engine feature branch
            renderGroup={params => (
              <Fragment key={params.key}>
                <MenuItem
                  disabled
                  sx={{
                    '&.Mui-disabled': { opacity: 1 },
                    borderTop: 1,
                    marginTop: 1,
                    borderTopColor: theme =>
                      alpha(theme.palette.text.primary, 0.1),
                  }}
                >
                  <Typography variant="overline" color="text.secondary">
                    {params.group}
                  </Typography>
                </MenuItem>
                {params.children}
              </Fragment>
            )}
            renderOption={(params, { value }) => (
              <MenuItem {...params} key={value} sx={{ whiteSpace: 'normal' }}>
                <Typography variant="body2">{value}</Typography>
              </MenuItem>
            )}
            size="small"
          />
        )}
      />
    </ContentGroup>
  ) : null;
};

export default PreFillFormSection;
