import { Button } from '@rossum/ui/material';
import { useEffect, useRef } from 'react';
import { Annotation } from '../../../../../types/annotation';
import { useConfirmButton } from './useConfirmButton';

type ConfirmButtonProps = {
  annotation: Annotation;
};

export const ConfirmButton = ({ annotation }: ConfirmButtonProps) => {
  const {
    disabled: confirmButtonDisabled,
    focused: confirmButtonFocused,
    startIcon: confirmButtonStartIcon,
    label: confirmButtonLabel,
    onClick: handleConfirmClick,
    onKeyDown: handleConfirmKeyDown,
  } = useConfirmButton(annotation);

  const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const confirmButton = confirmButtonRef.current;
    if (confirmButton) {
      if (confirmButtonFocused) {
        confirmButton.focus();
      } else {
        confirmButton.blur();
      }
    }
  }, [confirmButtonFocused]);

  return (
    <Button
      ref={confirmButtonRef}
      variant="contained"
      color="primary"
      sx={{ flexGrow: 1 }}
      disabled={confirmButtonDisabled}
      startIcon={confirmButtonStartIcon}
      onClick={handleConfirmClick}
      onKeyDownCapture={handleConfirmKeyDown}
    >
      {confirmButtonLabel}
    </Button>
  );
};
