import { ThemeType } from '@rossum/rossum-ui/theme';
import { CssBaseline, lighten, ThemeProvider } from '@rossum/ui/material';
import { resolveTheme } from '@rossum/ui/theme';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { mapValues } from 'remeda';
import { organizationSelector } from './redux/modules/organization/selectors';
import { OrganizationBranding } from './types/organization';
import { State } from './types/state';

export const HTML_FONT_SIZE = 10;

type StateProps = {
  themeType: ThemeType;
  branding?: OrganizationBranding;
};

type OwnProps = {
  children: React.ReactNode;
  theme?: 'dark' | 'light';
};

type Props = StateProps & OwnProps;

const RuiThemeProvider: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ themeType, branding, children, theme }) => {
  const themeConfig = useMemo(() => {
    const currentTheme = theme ?? (themeType === 'white' ? 'light' : 'dark');

    const resolvedTheme = resolveTheme(currentTheme, {
      // TODO: Proper resolution of all possible brandings?
      // we need mapping that takes into account current styling, branding options and
      // MUI mechanics - standalone task, this is just for primary color demo
      palette: branding?.colors?.primary
        ? {
            primary: {
              main: branding.colors.primary,
            },
          }
        : {},
      // needs to be added to respect font-size: 10px on html
      typography: {
        htmlFontSize: HTML_FONT_SIZE,
        ...(branding?.font
          ? {
              fontFamily: branding?.font,
            }
          : {}),
      },
      // TODO: Document this and add value different than 0
      ...(branding?.borderRadiuses
        ? {
            shape: {
              borderRadius: 0,
            },
          }
        : {}),
      components: {
        MuiSelect: {
          styleOverrides: {
            select: {
              height: 0,
              lineHeight: 1.5,
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            // Fix for beamer snippets with white titles on white background.
            '.beamerAnnouncementSnippetContent': {
              backgroundColor: 'white',
            },
            '.beamerAnnouncementSnippetTitle, .beamerAnnouncementSnippetText': {
              color: 'black',
            },
            body: {
              lineHeight: 1.42857143,
              fontSize: '1.4rem',
              letterSpacing: 'unset',
              '*::-webkit-scrollbar': {
                width: 8,
                height: 8,
              },
            },
          },
        },
      },
    });

    return {
      ...resolvedTheme,
      // Ensure all z-indexes are above the values used in our app
      // https://mui.com/customization/z-index/
      zIndex: mapValues(resolvedTheme.zIndex, zIndex => zIndex * 10),
    };
  }, [
    branding?.borderRadiuses,
    branding?.colors?.primary,
    branding?.font,
    theme,
    themeType,
  ]);

  useEffect(() => {
    const themePalette = themeConfig.palette;
    [
      ['--color-primary', themePalette.primary.main],
      ['--graybackground', themePalette.background.paper],
      ['--graybox', themePalette.background.default],
      ['--validationBackground', themePalette.background.paper],
      ['--annotation-footer', themePalette.background.paper],
      [
        '--uberBlack-rgb',
        themePalette.mode === 'dark' ? '18, 17, 23' : '244, 243, 246',
      ],
      [
        '--white-rgb',
        themePalette.mode === 'dark' ? '255, 255, 255' : '18, 17, 23',
      ],
      ['--dropdown-item-hover', lighten(themePalette.background.paper, 0.1)],
      [
        '--annotation-fake-select-background',
        themePalette.mode === 'dark' ? '#000' : '#E7E4EB',
      ],
    ].forEach(([variable, value]) => {
      document.documentElement.style.setProperty(variable, value);
    });
  }, [themeConfig.palette]);

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: State) => {
  const themeType =
    state.user.uiSettings.theme ??
    organizationSelector(state)?.uiSettings?.theme ??
    'dark';
  return {
    themeType,
    branding: organizationSelector(state)?.uiSettings?.branding,
  };
};

export default connect<StateProps, null, OwnProps, State>(mapStateToProps)(
  RuiThemeProvider
);
