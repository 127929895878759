import { useSelector } from 'react-redux';
import {
  tabByName,
  TabWithMenuName,
} from '../../../components/NavBar/navigationStructure';
import SidebarNavLink from '../../../components/Sidebar/SidebarNavLink';
import { useLabelsEnabled } from '../../../features/labels/hooks/useLabelsEnabled';
import { organizationSelector } from '../../../redux/modules/organization/selectors';
import { rulesEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { State } from '../../../types/state';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';
import SidebarSection from '../components/SidebarSection';

const SidebarItems = ({
  section,
}: {
  section: Extract<TabWithMenuName, 'extensions'>;
}) => {
  const orgUiFeatures = useSelector(
    (state: State) => organizationSelector(state).uiSettings?.features ?? {}
  );

  const isLabelsEnabled = useLabelsEnabled();
  const isFieldManagerEnabled = useFeatureFlag(
    'ac-4198-field-manager-improvements'
  );
  const isRulesEnabled = useSelector(rulesEnabledSelector);

  const tab = tabByName(section);
  const menuItems =
    tab.menu === undefined
      ? []
      : tab.menu.filter(
          m =>
            m.isVisible === undefined ||
            m.isVisible({
              features: {
                isLabelsEnabled,
                isFieldManagerEnabled,
                isEnginesEnabled: orgUiFeatures.engines,
                isRulesEnabled,
              },
            })
        );

  return (
    <SidebarSection label={`containers.settings.sidebar.sections.${section}`}>
      {menuItems.map(item => (
        <SidebarNavLink
          text={`containers.settings.sidebar.${item.name}`}
          to={item.link}
          dataCy={item.dataCy}
          key={`${item.name}-${section}`}
          isActive={item.isActive}
        />
      ))}
    </SidebarSection>
  );
};

export default SidebarItems;
