import { Queue } from '@rossum/api-client/queues';
import { Stack, Typography } from '@rossum/ui/material';
import { Document } from '../../../types/document';
import { Workspace } from '../../../types/workspace';
import { TruncatedName } from '../../../ui/truncated-name/TruncatedName';

type DocumentInformationProps = {
  document: Document | undefined;
  queue: Queue | undefined;
  workspace: Workspace | undefined;
  annotationsInStack: number;
  currentAnnotationIndex: number;
};

export const DocumentInformation = ({
  document,
  queue,
  workspace,
  annotationsInStack,
  currentAnnotationIndex,
}: DocumentInformationProps) => {
  const displayNavigationStack =
    currentAnnotationIndex !== -1 && annotationsInStack > 1;

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ alignItems: 'baseline', overflow: 'hidden' }}
    >
      <Typography
        variant="h6"
        component="span"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: 450,
          flexShrink: 1,
        }}
      >
        <TruncatedName name={document?.originalFileName ?? ''} />
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          alignItems: 'baseline',
          // flexBasis: '40%',
          flexGrow: 1,
        }}
      >
        {displayNavigationStack ? (
          <Typography variant="body2" color="text.secondary">
            <strong>{currentAnnotationIndex + 1}</strong>/{annotationsInStack}
          </Typography>
        ) : null}
        <Typography variant="body2" color="text.secondary">
          in
        </Typography>
        <Typography
          color="text.secondary"
          fontWeight="bold"
          sx={{ overflow: 'hidden' }}
          component="span"
        >
          <TruncatedName name={queue?.name ?? ''} />
        </Typography>
        <Typography
          component="span"
          color="text.secondary"
          sx={{ overflow: 'hidden' }}
        >
          <TruncatedName name={`(${workspace?.name})`} />
        </Typography>
      </Stack>
    </Stack>
  );
};
