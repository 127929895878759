import equal from 'fast-deep-equal/es6/react';
import {
  chain,
  find,
  first,
  get,
  includes,
  isEmpty,
  last,
  map,
  some,
  update,
  values,
} from 'lodash';
import { groupBy, partition } from 'remeda';
import { createSelector } from 'reselect';
import createDeepEqualSelector from '../../../lib/createDeepEqualSelector';
import { getDatapointPathFromSearch, parse } from '../../../lib/url';
import {
  AnyDatapointDataST,
  Children,
  Grid,
  MatchedTriggerRulesPerLevel,
  MultivalueDatapointDataST,
  PassiveBbox,
  SectionDatapointDataST,
  TupleDatapointDataFromSelector,
  TupleDatapointDataST,
} from '../../../types/datapoints';
import { AnyDatapointSchema } from '../../../types/schema';
import { State } from '../../../types/state';
import { addValueActiveSelector } from '../router/selectors';
import { canHaveBbox } from '../schema/helpers';
import { schemaMapSelector } from '../schema/schemaMapSelector';
import {
  complexLineItemsEnabledSelector,
  readOnlySelector,
} from '../ui/selectors';
import { getHighestPriorityMessage } from './helpers';
import { getVirtualGrids } from './helpers/getVirtualGrids';
import { findDatapointById } from './navigation/findDatapointIndex';
import {
  getTupleDatapointData,
  isBoundedDatapoint,
  isDatapointHidden,
  isSimpleDatapoint,
  isSuggestedTuple,
  isTableDatapoint,
  isVirtualDatapoint,
} from './typedHelpers';

// It's here because of circular dependency
const _schemaSelector = (state: State) => state.schema.content;
export const schemaSelector = createDeepEqualSelector(
  _schemaSelector,
  schemas => schemas
);

const _datapointsSelector = (state: State) => state.datapoints.content;
const _messagesSelector = (state: State) => state.datapoints.messages;

const footerExpandedSelector = (state: State): boolean =>
  state.ui.footerExpanded;

const locationSearchSelector = (state: State) => state.router.location.search;

export const messagesSelector = createDeepEqualSelector(
  _messagesSelector,
  messages => messages
);

export const allMessagesSelector = (state: State) =>
  state.datapoints.allMessages;

export const messagesStatsSelector = (state: State) => {
  return state.datapoints.messagesStats;
};

export const matchedTriggerRulesSelector = (state: State) =>
  state.datapoints.matchedTriggerRules;

export const hasMatchedTriggerRules = (
  triggerRules: MatchedTriggerRulesPerLevel
) =>
  !!triggerRules.annotationLevel.length ||
  !!Object.keys(triggerRules.datapointLevel).length;

export const isDeleteRecommendationSelector = (state: State) => {
  const { matchedTriggerRules } = state.datapoints;
  return hasMatchedTriggerRules(matchedTriggerRules);
};

export const isDeleteRecommendationInPathSelector = (state: State) => {
  const { deleteRecommendation } = parse(state.router.location.search);
  return !!deleteRecommendation;
};

export const validationInProgressSelector = (state: State) => {
  const { initiallyValidated, pendingValidation, unvalidatedContent } =
    state.datapoints;

  return !initiallyValidated || pendingValidation || unvalidatedContent;
};

export const datapointsSelector = createDeepEqualSelector(
  _datapointsSelector,
  content => content
);

export const datapointPathSelector = createDeepEqualSelector(
  locationSearchSelector,
  search => getDatapointPathFromSearch(search) as number[]
);

export const areDatapointsValidSelector: (_state: State) => boolean =
  createSelector(
    messagesSelector,
    messages => !some(values(messages), { type: 'error' })
  );

// private
type CollapseSplitter = (
  _state: State
) => [Array<AnyDatapointDataST>, Array<AnyDatapointDataST>];

const footerDatapointIdsSelector = createSelector(
  datapointsSelector,
  datapoints =>
    chain(datapoints)
      .filter(
        (datapoint): datapoint is TupleDatapointDataST =>
          datapoint.category === 'tuple'
      )
      .map(({ children }) => children)
      .flatten()
      .map(({ id }) => id)
      .value()
);

export const findParentsSchemas = (
  schema: Array<AnyDatapointSchema>,
  schemaId: string,
  parents: Array<AnyDatapointSchema> = []
): Array<AnyDatapointSchema> => {
  const maybeParent = schema.find(
    schemapoint =>
      'children' in schemapoint &&
      schemapoint.children &&
      includes(schemapoint.children, schemaId)
  );

  return maybeParent
    ? findParentsSchemas(schema, get(maybeParent, 'id'), [
        ...parents,
        maybeParent,
      ])
    : parents;
};

const isAnyParentHidden = (schema: Array<AnyDatapointSchema>, id: string) =>
  some(findParentsSchemas(schema, id), { hidden: true });

export const hiddenSchemaDictSelector = createDeepEqualSelector(
  schemaSelector,
  (schema: AnyDatapointSchema[] = []) =>
    schema.reduce<Record<string, boolean>>(
      (acc, { id, hidden }) => ({
        ...acc,
        [id]: hidden || isAnyParentHidden(schema, id),
      }),
      {}
    )
);

export const splitByVisibility = createSelector(
  datapointsSelector,
  hiddenSchemaDictSelector,
  footerDatapointIdsSelector,
  readOnlySelector,
  (datapoints, hiddenDict, footerDatapointIds, readOnly) => {
    // Ensure that partition() call runs in O(n) (previous implementation with Array.includes was O(n^2))
    const footerDatapointIdSet = new Set(footerDatapointIds);

    return partition(
      datapoints,
      ({ id, schemaId, hidden: dataHidden, schema }: AnyDatapointDataST) => {
        if (readOnly && get(schema, 'type') === 'button') return true;

        return footerDatapointIdSet.has(id)
          ? get(hiddenDict, schemaId)
          : isDatapointHidden(dataHidden, get(hiddenDict, schemaId));
      }
    );
  }
);

const splitByCollapsed: CollapseSplitter = createSelector(
  splitByVisibility,
  footerExpandedSelector,
  schemaSelector,
  ([, datapoints], footerExpanded, schema) => {
    if (footerExpanded) return [[], datapoints];

    return partition(datapoints, datapoint => {
      const datapointSchema =
        get(datapoint, 'schema') || find(schema, { id: datapoint.schemaId });
      return datapointSchema?.canCollapse === true;
    });
  }
);

// public

export const getVisibleDatapoints = createSelector(
  splitByVisibility,
  splitByCollapsed,
  ([hiddenDatapoints], [collapsedDatapoints, uncollapsedVisibleDatapoint]) => {
    const invisibleDatapointIds = [
      ...hiddenDatapoints.map(({ id }: AnyDatapointDataST) => id),
      ...collapsedDatapoints.map(({ id }) => id),
    ];

    return uncollapsedVisibleDatapoint.map<AnyDatapointDataST>(
      (datapoint, index) => ({
        ...datapoint,
        meta: { ...datapoint.meta, visibleIndex: index },
        ...('children' in datapoint
          ? {
              children: datapoint.children.filter(
                ({ id }: Children) => !invisibleDatapointIds.includes(id)
              ),
            }
          : {}),
      })
    );
  }
);

export const currentDatapointIdSelector = createSelector(
  datapointPathSelector,
  last
);

export const getCurrentSidebarDatapointId = createSelector(
  datapointPathSelector,
  ([, currentSidebarDatapointId]) => currentSidebarDatapointId
);

export const currentMultivalueDatapointSelector = createSelector(
  getCurrentSidebarDatapointId,
  getVisibleDatapoints,
  (multivalueId, datapoints) => {
    const maybeMultivalueDatapoint = find(datapoints, { id: multivalueId });

    if (
      !maybeMultivalueDatapoint ||
      ('category' in maybeMultivalueDatapoint &&
        maybeMultivalueDatapoint.category !== 'multivalue')
    )
      return null;

    return maybeMultivalueDatapoint;
  }
);

export const isParentTableDatapointSelectedSelector = createSelector(
  currentDatapointIdSelector,
  currentMultivalueDatapointSelector,
  (currentDatapointId, currentMultivalueDatapoint) =>
    !!currentMultivalueDatapoint &&
    currentDatapointId === currentMultivalueDatapoint.id &&
    !currentMultivalueDatapoint.meta.isSimpleMultivalue
);

export const shouldFocusAddValueSelector = createSelector(
  addValueActiveSelector,
  currentMultivalueDatapointSelector,
  (addValueActive, currentMultivalueDatapoint) =>
    addValueActive ||
    (currentMultivalueDatapoint &&
      currentMultivalueDatapoint.children.length === 0)
);

const splitDatapointsByActive = createSelector(
  currentDatapointIdSelector,
  getVisibleDatapoints,
  (currentDatapointId, datapoints) =>
    partition(datapoints, ({ id }) => id === currentDatapointId)
);

export const getTableDatapoints = createSelector(
  getVisibleDatapoints,
  currentMultivalueDatapointSelector,
  (datapoints, multivalueDatapoint) => {
    if (!datapoints || !datapoints.length || !multivalueDatapoint)
      return undefined;

    const {
      children,
      meta: { visibleIndex },
    } = multivalueDatapoint;

    if (!children || !children.length || visibleIndex === undefined) {
      return [];
    }

    const visibleDatapoint = datapoints[visibleIndex + 1];
    if (
      !visibleDatapoint ||
      !('children' in visibleDatapoint) ||
      !visibleDatapoint.children
    ) {
      return [];
    }

    const tupleLength = visibleDatapoint.children.length;
    const tupleIterator = Array.from(Array(tupleLength).keys());

    return children.map((_tupleId, index) => {
      const tupleIndex = visibleIndex + tupleLength * index + index + 1;
      const tuple = datapoints[tupleIndex];

      const columns = tupleIterator.map(childIndex => {
        const child = datapoints[tupleIndex + 1 + childIndex];
        return { id: child?.id, index: child?.meta.index };
      });

      return { ...tuple, children: columns };
    }) as TupleDatapointDataFromSelector[];
  }
);

export const getTableDatapointsWithIds = createSelector(
  getTableDatapoints,
  tuples =>
    [tuples, map(tuples, 'id')] as [TupleDatapointDataFromSelector[], number[]]
);

export const getCurrentTuple = createSelector(
  getTableDatapoints,
  datapointPathSelector,
  (tableDatapoints, [, , currentTupleId]) => {
    if (!tableDatapoints || !currentTupleId) return undefined;

    return tableDatapoints.find(({ id }) => currentTupleId === id);
  }
);

export const getPasiveBboxesSelector = createSelector(
  splitDatapointsByActive,
  getCurrentTuple,
  ([[currentDatapoint], passiveDatapoints], currentTuple) =>
    passiveDatapoints.reduce<PassiveBbox[][]>(
      (acc, { id, meta, schemaId, ...rest }) => {
        if (
          !('content' in rest) ||
          !rest.content ||
          !canHaveBbox(rest.schema)
        ) {
          return acc;
        }

        const { page, position } = rest.content;

        if (!page || !position) return acc;

        // TODO fix with TS Optional Chaining
        if (
          get(currentDatapoint, 'content.position') &&
          get(currentDatapoint, 'content.page') === page &&
          equal(get(currentDatapoint, 'content.position'), position)
        ) {
          return acc;
        }

        if (
          acc[page] &&
          acc[page].some(({ position: _position }) =>
            equal(position, _position)
          )
        ) {
          return acc;
        }

        const completed =
          'validationSources' in rest && !isEmpty(rest.validationSources);
        const isInCurrentTuple =
          currentTuple && currentTuple.id === meta.parentId;

        const passiveBboxes: PassiveBbox[][] = update(
          acc,
          page,
          (bboxes: PassiveBbox[][] = []) => [
            ...bboxes,
            { position, completed, id, isInCurrentTuple, page, schemaId },
          ]
        );

        return passiveBboxes;
      },
      []
    )
);

export const currentDatapointSelector = createSelector(
  splitDatapointsByActive,
  ([[currentDatapoint]]): AnyDatapointDataST | undefined => currentDatapoint
);

export const currentDatapointHasBbox = createSelector(
  currentDatapointSelector,
  currentDatapoint => {
    return currentDatapoint
      ? isSimpleDatapoint(currentDatapoint) &&
          !!currentDatapoint.content &&
          !!currentDatapoint.content.position
      : false;
  }
);

const currentDatapointSchemaSelector = createSelector(
  currentDatapointSelector,
  schemaMapSelector,
  (currentDatapoint, schemaMap) =>
    currentDatapoint ? schemaMap.get(currentDatapoint.schemaId) : undefined
);

export const getVisibleSections = createSelector(
  getVisibleDatapoints,
  (datapoints: Array<AnyDatapointDataST>): Array<SectionDatapointDataST> =>
    datapoints.filter(
      (datapoint): datapoint is SectionDatapointDataST =>
        datapoint.category === 'section' && datapoint.children.length > 0
    )
);

export const firstDatapointMessageSelector = createSelector(
  messagesSelector,
  messages => getHighestPriorityMessage(values(messages))
);

export const getDatapointLabel = createSelector(
  datapointsSelector,
  firstDatapointMessageSelector,
  (datapoints, message) =>
    get(
      findDatapointById(datapoints, Number(get(message, 'id'))),
      'schema.label'
    )
);

// new bounding boxes

export const getBoundedDatapointsPerPage = createSelector(
  getVisibleDatapoints,
  visibleDatapoints =>
    groupBy(visibleDatapoints.filter(isBoundedDatapoint), dp => dp.content.page)
);

export const canCreateRectangleSelector = createSelector(
  complexLineItemsEnabledSelector,
  (state: State) => state.ui.readOnly,
  currentDatapointSelector,
  currentDatapointSchemaSelector,
  (cli, readonly, dp, schema) => {
    return (
      !readonly &&
      !!dp &&
      !!schema &&
      ((isSimpleDatapoint(dp) && canHaveBbox(schema)) ||
        !!dp.meta.isSimpleMultivalue ||
        (isTableDatapoint(dp) && !cli))
    );
  }
);

export const isFooterOpenSelector = createSelector(
  currentMultivalueDatapointSelector,
  schemaMapSelector,
  (currentMultivalue, schemaMap) => {
    const firstChild = currentMultivalue
      ? first(schemaMap.get(currentMultivalue.schemaId)?.children)
      : undefined;
    return firstChild ? schemaMap.get(firstChild)?.category === 'tuple' : false;
  }
);

export const getGridsSelector = createSelector(
  datapointsSelector,
  schemaMapSelector,
  getBoundedDatapointsPerPage,
  isFooterOpenSelector,
  complexLineItemsEnabledSelector,
  (
    allDatapoints,
    schemaMap,
    pageDatapointsMap,
    isFooterOpen,
    complexLineItemsEnabled
  ) =>
    (multivalue: MultivalueDatapointDataST): Grid[] => {
      if (!complexLineItemsEnabled) {
        return get(multivalue, ['grid', 'parts'], []) as Grid[];
      }

      if (multivalue && isFooterOpen) {
        return getVirtualGrids(
          allDatapoints,
          schemaMap,
          pageDatapointsMap,
          multivalue
        );
      }
      return [];
    }
);

export const suggestedTuplesSelector = createSelector(
  currentMultivalueDatapointSelector,
  datapointsSelector,
  (currentMultivalueDatapoint, allDatapoints) =>
    currentMultivalueDatapoint
      ? allDatapoints
          .filter(
            (dp): dp is TupleDatapointDataST =>
              dp.category === 'tuple' &&
              isVirtualDatapoint(dp.id) &&
              dp.meta.parentId === currentMultivalueDatapoint.id
          )
          .filter(d =>
            isSuggestedTuple(getTupleDatapointData(d, allDatapoints))
          )
      : []
);
